// just for hotfix, prevent override other page styling
.InsiderManagement {
    input {
        width: 100%;
        background-color: #f0f2fd;
        font-family: Lato, sans-serif;
        color: #15357a;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        border-radius: 5px;
        height: 40px;

        ::placeholder {
            color: #2c71f0;
            opacity: 1;
        }

        &:hover,
        &:active,
        &:focus {
            border: 2px solid #6499fc;
            color: ＃6499fc
        }

        &:disabled {
            color: #5f5f5f;
            background-color: #eeeeee;
            user-select: none;
            pointer-events: none;
        }
    }

    .editor-field {
        margin-bottom: 0.5rem;
        width: 100%;

        .editor-label {
            font-family: "Lato", san-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: bold;
            line-height: 18px;
            color: #15357a;
            margin-bottom: 1%;
            margin-top: 0%;
        }

        .editor-input-wrapper {
            display: flex;
            flex-direction: row;
        }

        .input-group-text {
            width: auto;
            min-width: 8rem;
        }

        .autoComplete {
            width: 100%;
            position: relative;

            input+div {
                width: 100%;
            }
        }

        button {
            float: right;
            min-width: 10rem;
        }

        input {
            padding-left: 8px;
        }
    }

    .table-panel-container {
        display: flex;
        flex-direction: row;

        .table-panel-section {
            display: flex;
            flex-direction: column;
            flex: 1 1 50px;
            margin: 0 0.25rem;
        }

        table {
            margin: 0;

            tbody {
                tr {
                    cursor: pointer;

                    &:hover,
                    &.active {
                        background: #c0c3cd;
                        color: #16428b;
                    }
                }

                &::after {
                    content: ' ';
                    display: block;
                }
            }
        }

        .table-panel-context {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            width: 100%;
            height: 386px;
            overflow: auto;
            border: 1px solid #eeeeee;
            border-radius: 5px;
            margin-bottom: 0.25rem;
            background-color: #eeeeee;
        }
    }
}