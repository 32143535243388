tr.suspect-row.active {
    background-color: #cdd9fb !important;
}
tr.suspect-row td:not(:last-child),
tr.suspect-row:hover td:not(.action-col) {
    cursor: pointer;
}

tr.suspect-row td.action-col {
    pointer-events: none;
    height: 1px;
    padding: 0;
}

tr.suspect-row td.action-col .action-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 0.5rem;
}
tr.suspect-row td.action-col .action-wrapper .action {
    pointer-events: auto;
    cursor: pointer;
}
tr.suspect-row td.action-col .action-wrapper .action img {
    width: 1.25em;
}

.inspect table {
    margin: 0 !important;
}
.inspect .inspect-column {
    min-height: 10rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.1em;
}
.inspect .table-container {
    min-height: 33rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.1em;
    background-color: #f0f2fd;
    border-radius: 5px;
    overflow: auto;
}
.inspect .table-container tr{
    height: 3rem;
}
.inspect .table-container table{
    border-radius: 0;
}

.comment-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.25em;
    padding: 0.5rem 3rem;
}

.comment-editor.comment-wrapper {
    align-items: normal;
    gap: 0.5em;
    justify-content: "center";
}
